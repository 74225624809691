import {
  ContractType as ContractTypePartial,
  TenantPowerContractType,
} from "~/src/generated-sources/public";

export const ContractType = {
  ...TenantPowerContractType,
  ...ContractTypePartial,
};

export type ContractType = (typeof ContractType)[keyof typeof ContractType];
